.App {
  text-align: center;
}

.App{
  min-width: 2900px !important;
  overflow-y: hidden !important;
  overflow-x: scroll !important;
}
.headerNav{
  margin-left: 450px !important;
  margin-top: 40px !important;
} 
.appContainer {
  margin: 0 35% !important;
}
.cashoffer {
  margin: 90px 0px 90px 23% !important;
}
body,li,p,div{
  
    font-family: "aktiv-grotesk", Arial, Helvetica, sans-serif !important;
  
}
.getoffer button:hover{
  background: rgb(225, 37, 27) !important;
  color: #fff !important;
  border: none !important;
}
.ant-btn-primary:hover{
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #e2e2e2 !important;
}
li.ant-rate-star {
  position: relative;
  display: inline-block;
  /* color: inherit; */
  cursor: pointer;
  background: #f6f7f9;
  text-align: center;
  margin-left: 10px;
  /* color: #000 !important; */
  width: 24px;
  height: 24px;
  border-radius: 2px;
}
.ant-rate-star-second{
  color: #282c34 !important ;
}
.ant-rate-star:hover{
  background: #85CE06;
  color: #fff !important;
  border-radius: 2px !important;
}
.ant-rate-star-second:hover{
  background: #85CE06;
  color: #fff !important;
  border-radius: 2px !important;
}
.ant-rate-star-focused .ant-rate-star-second{
  color: #fff !important;
  background: #85CE06 !important;
  border-radius: 2px !important;
}
/* Responsiveness */
@media only screen and ( max-width: 4400px) {
  .App{
    min-width: 2900px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .headerNav{
    margin-left: 720px !important;
    margin-top: 40px !important;
  } 
  .appContainer {
    margin: 0 28% !important;
  }
  .about {
    margin: 9% 25% !important;
  }
  .rumbleon {
    margin: 9% 25% !important;
  }
  .cashoffer {
    margin: 90px 0px 90px 15% !important;
  }
  .careers{
    margin: 15% 0% 8% !important;
  }
  .contactus{
    margin: 0px 30% !important;
  }
  
  .gotspeedCards {
    margin: 2% 20% 4% !important;
  }
}
@media only screen and ( max-width: 3600px) {
  .App{
    min-width: 2900px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .headerNav{
    margin-left: 720px !important;
    margin-top: 40px !important;
  } 
  .appContainer {
    margin: 0 21% !important;
  }
  .about {
    margin: 9% 25% !important;
  }
  .rumbleon {
    margin: 9% 25% !important;
  }
  .cashoffer {
    margin: 90px 0px 90px 17% !important;
  }
  .careers{
    margin: 15% 2% 8% !important;
  }
  .contactus{
    margin: 0px 28% !important;
  }
  
  .gotspeedCards {
    margin: 2% 20% 4% !important;
  }
}
@media only screen and ( max-width: 2560px) {
  .App{
    min-width: 2400px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .headerNav{
    margin-left: 720px !important;
    margin-top: 40px !important;
  } 
  .appContainer {
    margin: 0 15% !important;
  }
  .about {
    margin: 9% 26% !important;
  }
  .rumbleon {
    margin: 9% 26% !important;
  }
  .cashoffer {
    margin: 90px 0px 90px 18% !important;
  }
  .careers{
    margin: 15% 4% 8% !important;
  }
  .contactus{
    margin: 0px 30% !important;
  }
  
  .gotspeedCards {
    margin: 2% 22% 4% !important;
  }
}
@media only screen and ( max-width: 2260px) {
  .App{
    min-width: 2100px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .appContainer {
    /* max-width: 1880px !important; */
    margin: 0 10% !important;
  }
  .headerNav{
    margin-left: 720px !important;
    margin-top: 40px !important;
  } 
  .about {
    margin: 9% 25% !important;
  }
  .careers{
    margin: 15% 4% 8% !important;
  }
  .careersOpr {
    margin: 0px 2% !important;
  }
  .rumbleon {
    margin: 9% 25% !important;
  }
  .gotspeedCards {
    margin: 2% 23% 4% !important;
  }
}
@media only screen and ( max-width: 1920px) {
  .App{
    min-width: 1800px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .appContainer {
    /* max-width: 1880px !important; */
    margin: 0 3% !important;
  }
  .headerNav{
    margin-left: 720px !important;
    margin-top: 40px !important;
  } 
  .about {
    margin: 9% 25% !important;
  }
  .careers{
    margin: 15% 6% 8% !important;
  }
  .careersOpr {
    margin: 0px 2% !important;
  }
  .rumbleon {
    margin: 9% 25% !important;
  }
  .gotspeedCards {
    margin: 2% 23% 4% !important;
  }
}
@media only screen and ( max-width: 1800px) {
  .App{
    min-width: 1800px !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  .appContainer {
    /* max-width: 1880px !important; */
    margin: 0% !important;
  }
}
@media only screen and ( max-width: 1440px) {

  .App{
    min-width: 1440px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
  .appContainer{
    margin: 0% !important;
  }
  .headerNav{
    margin-left: 350px !important;
    margin-top: 40px !important;
  } 
  .cashoffer {
    margin: 90px 0px 90px 164px!important;
  }
  .about {
    margin: 9% 22% !important;
  }
  .rumbleon {
    margin: 9% 22% !important;
  }
  .gotspeedCards {
    margin: 2% 14% 4% !important;
  }
  .careers img{
    width: 280px !important;
    height: 186px !important;
  }
  .careers{
    margin: 15% 4% 8% !important;
  }
  .contactus{
    margin: 0px 22% !important;
  }
  
}
@media only screen and ( max-width: 1024px) {

  .App{
    min-width: 1024px !important;
    /* overflow-y: hidden !important; */
    overflow-x: hidden !important;
  }
  .appContainer{
    margin: 0% !important;
  }
  .headerNav{
    margin-left: 30px !important;
    margin-top: 40px !important;
  } 
  .cashoffer {
    margin: 90px 0px 90px 74px!important;
  }
  .about {
    margin: 9% 18% !important;
  }
  .aboutContainer,.rumbleonContainer{
    width: 590px !important;
  }
  .getoffer {
    padding: 24px 0px !important;
    height: 120px;
    width: 350px;
    max-width: 500px;
    margin: 18% 6% !important;
  }
  .getoffer Button,.careers Button{
    width: 320px !important;
  }
  .getoffer input{
    width: 320px !important;
  }
  .rumbleon {
    margin: 9% 18% !important;
  }
  .gotspeedCards {
    margin: 2% 32% 4% !important;
    display: block !important;
  }
  .gotspeedCards img{
    height: 137px !important;
    width: 263px !important;
  }
  .gotspeed Button{
    width: 300px !important;
  }
  .gotspeedCardOne,.gotspeedCardTwo,.gotspeedCardThree {
    width: 365px !important;
    margin: 0% !important;
   }
   .careers img{
    width: 260px !important;
    height: 186px !important;
  }
  .careersOpr {
    width: 335px !important;
    margin: 0px 4% !important;
  }
  .careers{
    margin: 15% 0% 8% !important;
  }
  .contactus{
    margin: 0px 14% !important;
  }
  
}
@media only screen and ( max-width: 820px) {

  .App{
    min-width: 768px !important;
    /* overflow-y: hidden !important; */
    overflow-x: hidden !important;
  }
  .appContainer{
    margin: 0% !important;
  }
  .headerNav{
    width: 480px !important;
    margin-left: 30px !important;
    margin-top: 40px !important;
  } 
  .cashoffer {
    margin: 90px 0px 90px -40px!important;
  }
  .cashofferContainer{
    width: 345px !important;
    margin: 9% 5% !important;
    text-align: center !important;
  }
  div#careers {
    width:90% !important;
  }
  .about {
    margin: 9% 9% !important;
  }
  .aboutContainer,.rumbleonContainer{
    width: 590px !important;
  }
  .getoffer {
    padding: 24px 0px !important;
    height: 124px;
    width: 350px;
    max-width: 500px;
    margin: 18% 6% !important;
  }
  .getoffer Button,.careers Button{
    width: 320px !important;
  }
  .getoffer input{
    width: 320px !important;
  }
  .rumbleon {
    margin: 9% 9% !important;
  }
  .gotspeedCards {
    margin: 2% 26% 4% !important;
  }
  /* .gotspeedCards img{
    height: 137px !important;
    width: 263px !important;
  } */
  .gotspeedCardOne,.gotspeedCardTwo,.gotspeedCardThree {
    width: 365px !important;
    margin: 0% !important;
   }
  .careers img{
    width: 260px !important;
    height: 186px !important;
  }
  img.careersImg1 {
    margin: -76px 28px 10px 36px !important;
  }
  img.careersImg2 {
    margin: 0% !important;
    margin: -76px 0px 10px 10px !important;
  }
  .careersOpr {
    width: 490px !important;
    margin: 0px 18% !important;
  }
  .careers{
    margin: 15% 0% 8% !important;
    display: block !important;
  }
  .contactus{
    margin: 0px 6% !important;
  }
  
}
@media only screen and (max-width: 600px) {
  .App{
    min-width: 100% !important;
  }
  .appContainer{
    margin: 0% !important;
  }
  .cashofferContainer{
    width: 300px !important;
    margin: 0% 10% !important;
    text-align: center !important;
  }
  .gotspeedContainer {
    max-width: 400px !important;
    margin: 0 10% !important;
  }
  .cashofferPara {
    font-size: 38px !important;
    line-height: 38px !important;
  }
  .cashoffertext{
    text-align: start;
    width: 300px !important;
    line-height:'26px' !important;
  }
  div#about {
        height: 100% !important;
        width: 100% !important;
  }
  .App p{
    /* font-size: 22px !important; */
  }
  .about {
    margin: 9% 6% !important;
  }
  .rumbleonImgbag,div#rumbleon {
    height: 100% !important;
  }
  .rumbleon {
    margin: 9% 4% !important;
  }
  .rumbleon img{
    width: 280px !important;
    height: 22px !important;
    margin: 10px 0% !important;
  }
  .cashoffer {
    display: block !important;
    margin: 0% 15% !important;
  }
  .getoffer {
    padding: 28px 0px !important;
    height: 124px;
    width: 322px;
    max-width: 500px;
    margin: 4% 9% !important;
  }
  .aboutContainer,.rumbleonContainer{
    margin: 0% 15%;
    max-width: 300px !important;
  }
  .getoffer input{
    width: 280px !important;
  }
  .getoffer Button,.careers Button{
    width: 280px !important;
  }
  .gotspeedCardOne,.gotspeedCardTwo,.gotspeedCardThree {
    width: 426px !important;
    margin: 0 27% !important;
   }
  .gotspeedCards {
    display: block !important;
    margin: 2% -68% 4% !important;
  }
  .gotspeedCards img{
    height: 137px !important;
    width: 263px !important;
  }
  .gotspeed {
    height: 100% !important;
    padding: 25px 0;
  }
  .gotspeedCardOne{
    /* margin-left: 10px !important; */
  }
  .gotspeed Button{
    width: 280px !important;
    /* margin-left: -35% !important; */
  }
  .gotspeedCom {
    /* margin-left: -35%; */
    width: 280px !important;
    height: 47px !important;
  }
  .gotspeedtext1{
    margin: 0 36% !important;
  }
  .gotspeedtext2{
    margin: 0 36% !important;
  }
  .gotspeedtext{
    margin: 0 10% !important;
  }
  #contactus{
    height: 100%!important;
  }
  .contactus{
    display: block !important;
    margin: 0 20% !important;
    
  }
  .contactus img{
    width: 280px !important;
    height: 119px !important;
    
  }
  .contactusImg{
    margin: 0% !important;
  }
  .contactus-rights{
    margin-left: 18% !important;
    margin-right: 10% !important;
  }
  .careers{
    display: block !important;
    margin: 15% 12% 8% !important;
  }
  .careers img{
    width: 280px !important;
    height: 186px !important;
    margin: 12% !important;
  }

  .careersImg1,.careersImg2{
    margin: 12% !important;
    display: block;

  }
  .careersOpr{
    margin-top: -35px !important;
    max-width: 300px !important;
    margin: -20px 10% !important;
  }
  .header{
    margin: 2% 24% !important;
    /* height: 102px !important; */
  }
  .header img{
    width: 236px !important;
    height: 99px !important;
  }
  .headerNav{
    margin: 0% 20% !important;
   display:none !important;
  }
}
@media only screen and (max-width: 425px) {
  .App{
    min-width: 100% !important;
    /* overflow-x: hidden !important; */
  }
  .appContainer{
    margin: 0% !important;
  }
  .cashofferContainer{
    width: 300px !important;
    margin: 0% 10% !important;
    text-align: center !important;
  }
  .gotspeedContainer {
    max-width: 360px !important;
    margin: 0 4% !important;
  }
  .cashofferPara {
    font-size: 38px !important;
    line-height: 38px !important;
  }
  .cashoffertext{
    text-align: start;
    width: 300px !important;
    line-height:'26px' !important;
  }
  div#about {
        height: 100% !important;
        width: 100% !important;
  }
  .App p{
    /* font-size: 22px !important; */
  }
  .about {
    margin: 9% 8% !important;
  }
  .rumbleonImgbag,div#rumbleon {
    height: 100% !important;
  }
  .rumbleon {
    margin: 9% 5% !important;
  }
  .rumbleon img{
    width: 280px !important;
    height: 22px !important;
    margin: 10px 0% !important;
  }
  .cashoffer {
    display: block !important;
    margin:0% 4% !important;
  }
  .getoffer {
    padding: 28px 0px !important;
        height: 124px;
    width: 322px;
    max-width: 500px;
    margin: 4% 9% !important;
  }
  .aboutContainer{
    margin: 0% -2% !important;
    max-width: 300px !important;
  }
  .rumbleonContainer{
    margin: 0% !important;
    max-width: 300px !important;
  }
  .getoffer input{
    width: 280px !important;
  }
  .getoffer Button,.careers Button{
    width: 280px !important;
  }
  .gotspeedCardOne,.gotspeedCardTwo,.gotspeedCardThree {
    width: 426px !important;
    margin: 0 26% !important;
   }
  .gotspeedCards {
    display: block !important;
    margin: 2% -68% 4% !important;
  }
  .gotspeedCards img{
    height: 137px !important;
    width: 263px !important;
  }
  .gotspeed {
    height: 100% !important;
    padding: 25px 0;
  }
  .gotspeedCardOne{
    /* margin-left: 10px !important; */
  }
  .gotspeed Button{
    width: 280px !important;
    /* margin-left: -35% !important; */
  }
  .gotspeedCom {
    /* margin-left: -35%; */
    width: 280px !important;
    height: 47px !important;
  }
  .gotspeedtext1{
    margin: 0 36% !important;
  }
  .gotspeedtext2{
    margin: 0 36% !important;
  }
  .gotspeedtext{
    margin: 0 10% !important;
  }
  #contactus{
    height: 100%!important;
  }
  .contactusTxt{
   text-align: center !important;  
  }
  .contactus{
    display: block !important;
    margin: 0 15% !important;
    
  }
  .contactus img{
    width: 280px !important;
    height: 119px !important;
    
  }
  .contactusImg{
    margin: 0% !important;
  }
  .contactus-rights{
    margin-left: 18% !important;
    margin-right: 18% !important;
  }
  .careers{
    display: block !important;
    margin: 15% 0% 8% !important;
  }
  .careers img{
    width: 280px !important;
    height: 186px !important;
    margin: 12% !important;
  }

  .careersImg1,.careersImg2{
    margin: 12% !important;
    display: block;

  }
  .careersOpr{
    margin-top: -35px !important;
    max-width: 300px !important;
    margin: -20px 10% !important;
  }
  .header{
    margin: 2% 12% !important;
    /* height: 102px !important; */
  }
  .header img{
    width: 236px !important;
    height: 99px !important;
  }
  .headerNav{
    margin: 0% 20% !important;
   display:none !important;
  }
}
@media only screen and (max-width: 375px) {
  .cashoffer {
    display: block !important;
    margin: 0% 0% !important;
}
.aboutContainer {
  margin: 0% -8% !important;
  max-width: 300px !important;
}
.gotspeedContainer {
  max-width: 370px !important;
  margin: 0 -2% !important;
}
.rumbleonContainer {
  margin: 0% -6% !important;
  max-width: 300px !important;
}
.contactus {
  display: block !important;
  margin: 0 10% !important;
}
.careers {
  display: block !important;
  margin: 15% -6% 8% !important;
}
}
  
@media only screen and (max-width: 320px) {
  .App{
    min-width: 100% !important;
    overflow-x: hidden !important;
  }
  .header {
    margin: 2% 2% !important;
    /* height: 102px !important; */
  }
  .aboutContainer {
    margin: 0% -18% !important;
    max-width: 270px !important;
 }
 .getoffer input {
  width: 270px !important;
 }
 .getoffer Button {
  width: 270px !important;
 }
 .gotspeedCom {
  margin-left: -13%;
  width: 280px !important;
  height: 47px !important;
 }
 .gotspeedContainer {
  max-width: 324px !important;
  margin: 0% -4% !important;
}
.gotspeed Button {
  width: 280px !important;
  margin-left: -12% !important;
}
 .contactusImg {
  margin: 0% -22% !important;
  width: 260px !important;
}
 .rumbleonContainer {
  margin: 0% -16% !important;
  max-width: 270px !important;
 }
 .careers {
  display: block !important;
  margin: 15% -16% 8% !important;
  }
  .cashofferContainer {
    width: 275px !important;
    margin: 0% 3% !important;
    text-align: center !important;
  }
  .cashoffertext {
    text-align: start;
    width: 250px !important;
    line-height: '26px' !important;
  }
  .getoffer {
    padding: 28px 0px !important;
    height: 228px;
    width: 280px;
    max-width: 300px;
    margin: 0% 0% !important;
   }
  .gotspeedCards {
    display: block !important;
    margin: 2% -98% 4% !important;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
